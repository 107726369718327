<template>
  <div class="imgBox">
    <div class="bigImgContainer">
      <div class="bSC" :style="{ transform: 'translateX(' + translate + '00%)' }">
        <img v-for="item in imgList" :key="item" class="bigShowImg" :src="baseUrl + item" alt="" />
        <!--         <img class="bigShowImg" src="../../static/img/showImg1.jpg" />
        <img class="bigShowImg" src="../../static/img/showImg2.jpg" />
        <img class="bigShowImg" src="../../static/img/showImg3.jpg" /> -->
      </div>
      <img @click="next" class="nextImgBtn" src="../../static/img/Vector.png" alt="" />
      <img @click="last" class="lastImgBtn" src="../../static/img/Vector.png" alt="" />
    </div>

    <div class="smallImgContainer" ref="swiper">
      <!--       <div class="smallShowImg" @click="one">
        <img src="../../static/img/showImg1.jpg" alt="" />
        <div v-show="oneMutex" class="active"></div>
      </div>
      <div class="smallShowImg" @click="two">
        <img src="../../static/img/showImg2.jpg" alt="" />
        <div v-show="twoMutex" class="active"></div>
      </div>
      <div class="smallShowImg" @click="three">
        <img src="../../static/img/showImg3.jpg" alt="" />
        <div v-show="threeMutex" class="active"></div>
      </div> -->
      <div class="smallShowImg" v-for="(item, index) in imgList" :key="item" @click="swiper(index)">
        <img :src="baseUrl + item" alt="" />
        <div v-show="index == translateX" class="active"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageBox",
  data() {
    return {
      translateX: 0,
      timer: null,
      oneMutex: false,
      twoMutex: false,
      threeMutex: false,
      swiperIndex: 0,
      imgList: [],
      baseUrl: "http://api.ligy.cn/",
    };
  },
  methods: {
    last() {
      this.translateX -= 1;
      if (this.translateX < 0) {
        this.translateX = 2;
      }
    },
    next() {
      clearInterval(this.timer);
      this.translateX += 1;
      if (this.translateX > 2) {
        this.translateX = 0;
      }
      this.timer = setInterval(() => {
        this.next();
      }, 2000);
    },
    swiper(index) {
      clearInterval(this.timer);
      this.translateX = index;
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.next();
    }, 2000);
  },
  computed: {
    translate() {
      return -this.translateX;
    },
  },
  created() {
    this.$axios({
      method: "GET",
      url: "/website/getMainPageImg",
    }).then((res) => (this.imgList = res.data.data.content));
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.imgBox {
  position: relative;
  width: 100%;
}

.bigImgContainer {
  position: relative;
  width: 100%;
  height: 60vw;
  max-height: 600px;
  overflow: hidden;
}

.bigShowImg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  /* max-height: 600px; */
  transition: 0.5s ease-in-out;
}

.bSC {
  position: absolute;
  top: 0;
  width: 100%;
  height: 600px;
  transition: 0.5s ease-in-out;
}

.bigShowImg:nth-of-type(1) {
  left: 0%;
}

.bigShowImg:nth-of-type(2) {
  left: 100%;
}

.bigShowImg:nth-of-type(3) {
  left: 200%;
}

.nextImgBtn {
  width: 16px;
  position: absolute;
  right: 2.5%;
  top: 45%;
}

.lastImgBtn {
  width: 16px;
  position: absolute;
  left: 2.5%;
  top: 45%;
  transform: rotateY(180deg);
}

.smallImgContainer {
  position: relative;
  bottom: 45px;
  margin: 0 auto;
  display: flex;
  width: 685px;
  max-height: 103px;
  justify-content: space-between;
  align-items: center;
}

.smallShowImg {
  max-width: 200px; /*165 */
  width: 22vw;
  height: 15vw;
  max-height: 103px; /*85 */
  position: relative;
}

.smallShowImg > img {
  width: 100%;
  height: 100%;
}

/* .smallShowImg:hover:before{
  position: absolute;
  content: "";
  width: 198px;
  height: 107px;
  border: 2px solid white;
}
 */
.active {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: 100%;
  height: 107px;
  border: 2px solid white;
}

/*手机端适配*/
@media screen and (max-width: 800px) {
  .smallImgContainer {
    display: none;
  }
  .imgBox,
  .bigImgContainer,
  .bSC,
  .bigShowImg {
    width: 100%;
    height: 300px;
    padding: 0;
  }

  .bigImgContainer {
    position: relative;
  }

  .nextImgBtn,
  .lastImgBtn {
    width: 16px;
    position: absolute;
    right: 2.5%;
    top: 45%;
  }

  .lastImgBtn {
    transform: rotateY(180deg);
  }
}
</style>
