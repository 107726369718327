<template>
  <div class="livesContainer">
    <index-title-text :text="liveText" :link="link"/>
    <div class="livesItemList">
      <div class="livesItem">
        <img class="lImg" src="../../static/img/live1.png" alt="" />
        <p class="lTest">Face Detection Gate</p>
      </div>
      <div class="livesItem">
        <img class="lImg" src="../../static/img/live2.png" alt="" />
        <p class="lTest">Front Door</p>
      </div>
      <div class="livesItem">
        <img class="lImg" src="../../static/img/live3.png" alt="" />
        <p class="lTest">Rest Zone</p>
      </div>
      <div class="livesItem">
        <img class="lImg" src="../../static/img/live4.png" alt="" />
        <p class="lTest">Culture Billboard</p>
      </div>
    </div>
  </div>
</template>
<script>
import IndexTitleText from '../../components/IndexTitleText.vue';

export default {
  components: { IndexTitleText },
  data() {
    return {
      liveText: 'Routine',
      link: '/EN',
    };
  },
};
</script>

<style scoped>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.livesContainer {
  width: 100%;
  height: 600px;
  margin-bottom: 50px;
}

.livesItemList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 41px;
  grid-row-gap: 21.5px;
  width: 85%;
  position: relative;
  top: 25px;
  margin-left: 7.5%;
}

.livesItem {
  position: relative;
  width: 100%;
  height: 100%;
}

.livesItem > .lImg {
  width: 100%;
  height: 165px;
  z-index: -1;
}

.livesItem > .lTest {
  text-align: center;
  line-height: 165px;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: PingFang SC;
  font-style: normal;
  font-size: 32px;
  /* identical to box height */
  color: #ffffff;
  z-index: 1;
  background: rgba(4, 29, 114, 0.2);
  margin: 0;
}

@media screen and (max-width: 800px) {
  .livesContainer {
    margin-bottom: 0px;
    height: 530px;
  }

  .livesItemList {
    grid-column-gap: 20.5px;
    grid-row-gap: 15.5px;
  }

  .livesItem > .lTest {
    font-size: 18px;
  }
}

@media screen and (min-width: 1500px){
  .livesContainer{
    height: 1000px;
  }

  .livesItem > .lImg {
    width: 100%;
    height: 300px;
    z-index: -1;
  }

  .livesItem > .lTest {
    line-height: 304px;
  }
}
</style>
